<template>
  <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />
  <div class="chat-interface">
    <div class="chat-wrapper" :class="{ active: isChatOpen }">
      <div class="chat-content">
        <header class="chat-header">
          <img src="@/assets/white-logo.svg" width="150" alt="logo" v-if="showLogo" />
          <!-- Removed token input -->
        </header>

        <div class="chat-body" id="chat-body">
          <div class="chat-message" v-for="(message, index) in messages" :key="index" :class="message.sender">
            <small>{{ message.sender === 'me' ? 'You' : 'Model' }} - {{ formatMessageTime(message.datetime) }}</small>
            <p v-if="message.content">
              <span v-html="message.content"></span>
              <button v-if="message.content" @click="copyToClipboard(message.contentRaw, $event)" class="copy-button"><span class="material-symbols-outlined">content_copy</span> Copy</button>
            </p>
            <div v-if="message.images && message.images.length" class="chat-images">
              <div v-for="(image, imgIndex) in message.images" :key="imgIndex" class="chat-image-container">
                <img :src="'data:image/png;base64,' + image" alt="Response image" class="chat-image" />
                <button 
                  class="download-button" 
                  @click="downloadImage(image, imgIndex)">
                  <span class="material-symbols-outlined">download</span>Download
                </button>
                <button 
                  class="copy-image-button" 
                  @click="copyImage(image, $event)">
                  <span class="material-symbols-outlined">content_copy</span>Copy
                </button>
              </div>
            </div>

            <div v-if="message.buttons && message.buttons.length || message.uploadButtons && message.uploadButtons.length" class="chat-buttons">
              <button v-for="(button, btnIndex) in message.buttons" :key="btnIndex" @click="sendButtonMessage(button.message)" class="chat-button">
                <span class="material-symbols-outlined">chat_bubble</span>{{ button.text }}
              </button>
              <button v-for="(uploadButton, uploadBtnIndex) in message.uploadButtons" :key="uploadBtnIndex" @click="uploadFileAndSendButtonMessage(uploadButton.message)" class="chat-button">
                <span class="material-symbols-outlined">upload_file</span>{{ uploadButton.text }}
              </button>
            </div>

          </div>

          <div v-if="isLoading" class="chat-message bot">
            <p>Calculando...</p>
          </div>
        </div>

        <footer class="chat-footer">
          <input class="message-input" type="text" v-model="currentMessage" placeholder="Type a message..."
            @focus="isInputFocused = true" @blur="isInputFocused = false" @keydown.enter="sendMessage" />

          <div class="file-input-wrapper">
            <span
              v-if="fileIsSelected"
              @click="clearFileInput"
              class="material-symbols-outlined"
              >close</span
            >
            <input
              type="file"
              name="fileInput"
              id="fileInput"
              @change="handleFileUpload"
            />
            <label for="fileInput">Upload portfolio</label>
          </div>

          <label @click="sendFeedback(true)" class="like">
            <img src="@/assets/like.svg" alt="like" width="24" />
          </label>
          <label @click="sendFeedback(false)" class="dislike">
            <img src="@/assets/like.svg" alt="dislike" width="24" style="transform: rotate(180deg);" />
          </label>
        </footer>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, onUnmounted } from "vue";
import { marked } from "marked";
import axios from "axios"; // Import axios for API requests

const isChatOpen = ref(true);
const currentMessage = ref("");
const token = ref(""); // Token for API requests
const isInputFocused = ref(false);
const isLoading = ref(false);
const messages = ref([]);
const selectedOption = ref("doc");
const showLogo = ref(true);
const fileIsSelected = ref(false);
const iframePortfolio = ref(null);

marked.use({ breaks: true });

const scrollToBottom = () => {
  const chatBody = document.getElementById("chat-body");
  if (chatBody) chatBody.scrollTop = chatBody.scrollHeight;
};

const formatMessageTime = (datetime) => {
  return datetime.toLocaleString();
};

const extractImagesAndButtons = (response) => {
  const imageMatches = response.match(/\[\[(.*?)\]\]/g) || [];
    const images = imageMatches.map(match => {
        // Remove the [[ and ]] markers from the match
        const base64Content = match.slice(2, -2);
        return base64Content;
    });

    const buttonMatches = response.match(/\{\{(.*?)\}\}/g) || [];
    const buttons = [];
    const uploadButtons = [];

    buttonMatches.forEach(match => {
      let [text, message] = match.slice(2, -2).split("|");
      if (text.includes("::upload::")) {
        text = text.replace("::upload::", "");
        uploadButtons.push({ text, message });
      } else {
        buttons.push({ text, message });
      }
    });

    // Remove the [[ and ]] markers from the response
    const cleanedResponse = response.replace(/\[\[(.*?)\]\]/g, "").replace(/\{\{(.*?)\}\}/g, "").trim();
    return { images, buttons, uploadButtons, cleanedResponse };
};

const copyToClipboard = (content, event) => {
  // content = content.replace(/<br>/g, "\n");

  const button = event.target.closest('button'); // Get the clicked button
  if (!button) return;

  const originalInnerHTML = button.innerHTML; // Save the original button text
  button.innerHTML = "Copied!"; // Set to "Copied"
  navigator.clipboard.writeText(content).then(() => {
    setTimeout(() => {
      button.innerHTML = originalInnerHTML; // Restore original text after a delay
    }, 2000); // 2 seconds delay
  }).catch((err) => {
    console.error("Failed to copy:", err);
    button.innerHTML = originalInnerHTML; // Restore immediately on failure
  });
};

const downloadImage = (image, index) => {
  const link = document.createElement("a");
  link.href = `data:image/png;base64,${image}`;
  link.download = `image_${index}.png`;
  link.click();
};

const copyImage = (image, event) => {
  try {
        // Convert the Base64 string to a Blob
        const base64Data = image.replace(/^data:image\/png;base64,/, ""); // Remove the prefix
        const binaryData = atob(base64Data); // Decode Base64 to binary
        const byteArray = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
            byteArray[i] = binaryData.charCodeAt(i);
        }
        const blob = new Blob([byteArray], { type: "image/png" });

        // Create a ClipboardItem
        const clipboardItem = new ClipboardItem({ "image/png": blob });

        // Write the image to the clipboard
        navigator.clipboard.write([clipboardItem]).then(() => {
            console.log("Image copied to clipboard!");
        }).catch((error) => {
            console.error("Failed to copy image to clipboard:", error);
        });

        // Save the html content of the button, set it to "Copied!" and then restore it after 2 seconds
        const button = event.target.closest("button");
        const originalInnerHTML = button.innerHTML;
        button.innerHTML = "Copied!";
        setTimeout(() => {
            button.innerHTML = originalInnerHTML;
        }, 2000);

    } catch (error) {
        console.error("Failed to copy image to clipboard:", error);
    }
};

const sendMessage = async (formDataCSV = null) => {
  if (!currentMessage.value.trim()) return;

  // Hide all chat buttons containers
  // const chatButtons = document.querySelectorAll(".chat-buttons");
  // chatButtons.forEach(button => button.style.display = "none");

  messages.value.push({
    sender: "me",
    content: currentMessage.value,
    contentRaw: currentMessage.value,
    datetime: new Date(),
  });

  const message_content = currentMessage.value; // eslint-disable-line no-unused-vars
  currentMessage.value = "";
  scrollToBottom();

  isLoading.value = true;

  // Set search parameters based on dropdown selection
  const do_similarity_search = selectedOption.value === "doc" || selectedOption.value === "both"; // eslint-disable-line no-unused-vars
  const do_numerical_search = selectedOption.value === "num" || selectedOption.value === "both"; // eslint-disable-line no-unused-vars

  try {
    if (!token.value) {
      throw new Error("Empty token");
    }

    // Send uploaded portfolio to the API
    let formData = null;
    // Use provided formDataCSV, save it in memory and clear the file input
    if (formDataCSV) {
      formData = formDataCSV;
      iframePortfolio.value = formDataCSV;
      clearFileInput();
    } else {
      // If a file was uploaded, use the uploaded file
      const userUploadedFiles = document.getElementById("fileInput").files;
      if (userUploadedFiles != null && userUploadedFiles.length > 0) {
        formData = new FormData();
        console.log("Uploaded files:", userUploadedFiles);
        Array.from(userUploadedFiles).forEach((file) => {
          formData.append("fileList", file);
        });
      // If no file was uploaded, use the portfolio from the iframe if present
      } else if (iframePortfolio.value) {
        formData = iframePortfolio.value;
      }
    }
    
    /*
    formData.append("question", message_content);
    formData.append("do_similarity_search", do_similarity_search);
    formData.append("do_numerical_search", do_numerical_search);
    */

    let url = `https://fastapi.addin.brokvisor.com/users/chat`;
    url += `?question=${message_content}&do_similarity_search=${do_similarity_search}&do_numerical_search=${do_numerical_search}`;

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${token.value}`, // Include Bearer token
        "Content-Type": "multipart/form-data",
      },
    };

    if (formData) {
      config.data = formData;
    }

    const response = await axios.request(config);

    // DEBUG: Mock response to test image display
    // const response = { data: "Hello, how can I help you? [[iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAADElEQVR4nGNgYGAAAAAEAAH2FzhVAAAAAElFTkSuQmCC]] [[iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAADElEQVR4nGP4//8/AAX+Av4N70a4AAAAAElFTkSuQmCC]] {{Say hi|Hello, how are you?}} {{Say bye|Goodbye}}" };
    // const response = { data: "Hello, how can I help you? {{Say hi|Hello, how are you?}} {{Say bye|Goodbye}} {{Say hi|Hello, how are you?}} {{Say bye|Goodbye}} {{Say hi|Hello, how are you?}} {{Say bye|Goodbye}} {{Say hi|Hello, how are you?}} {{Say bye|Goodbye}} {{Say hi|Hello, how are you?}} {{Say bye|Goodbye}} {{Say hi|Hello, how are you?}} {{Say bye|Goodbye}} {{Say hi|Hello, how are you?}} {{Say bye|Goodbye}} {{Say hi|Hello, how are you?}} {{Say bye|Goodbye}} {{Say hi|Hello, how are you?}} {{Say bye|Goodbye}}" };
    // const response = { data: "Hello, how can I help you? [[iVBORw0KGgoAAAANSUhEUgAAABAAAAAJCAIAAAC0SDtlAAABhGlDQ1BJQ0MgcHJvZmlsZQAAKJF9kT1Iw0AcxV9TRdFKByuKOGSoTnZREcdSxSJYKG2FVh1MLv2CJg1Jiouj4Fpw8GOx6uDirKuDqyAIfoA4OzgpukiJ/0sKLWI8OO7Hu3uPu3eA0Kgw1eyKAqpmGal4TMzmVsWeV/RjCEGEMSwxU0+kFzPwHF/38PH1LsKzvM/9OQaUvMkAn0gcZbphEW8Qz25aOud94hArSQrxOfGkQRckfuS67PIb56LDAs8MGZnUPHGIWCx2sNzBrGSoxDPEYUXVKF/Iuqxw3uKsVmqsdU/+wkBeW0lzneYY4lhCAkmIkFFDGRVYiNCqkWIiRfsxD/+o40+SSyZXGYwcC6hCheT4wf/gd7dmYXrKTQrEgO4X2/4YB3p2gWbdtr+Pbbt5AvifgSut7a82gLlP0uttLXwEBLeBi+u2Ju8BlzvAyJMuGZIj+WkKhQLwfkbflAMGb4G+Nbe31j5OH4AMdbV8AxwcAhNFyl73eHdvZ2//nmn19wPG2HLItlGvzgAAAAlwSFlzAAAuIwAALiMBeKU/dgAAABdJREFUGNNjlGt+wUAKYGIgEYxqoIkGALJ9AZtx5nl+AAAAAElFTkSuQmCC]] [[iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAADElEQVR4nGP4//8/AAX+Av4N70a4AAAAAElFTkSuQmCC]]" };
    // const response = { data: "Hola, soy el asistente de Inteligencia Artificial de Brokvisor. Estoy preparado para responder preguntas económicas y financieras.\nPuedes preguntarme sobre indicadores macroeconómicos, acciones, índices, fondos de inversión y mucho más.\nTambién puedo proporcionarte información sobre tus carteras en materia de renta variable y renta fija, distribución por regiones y sectores, etc.\n{{¿Qué resultados están mostrando los fondos europeos?}} {{¿Cómo ha ido el sector de biotecnología?}} {{¿Cómo se espera que sea el rendimiento de AAPL?}} {{¿Cómo va mi cartera?}}" }
    // const response = { data: "Por favor, especifica la cartera actual del cliente. {{No hay cartera para comparar|Razones para recomendar cartera 'Pal Orienta3 (1)' sin comparar a otra cartera}} {{::upload:: Subir cartera|Como va mi cartera 'Pal Orienta3 (1)' comparando con cartera subida?}}" }

    const { images, buttons, uploadButtons, cleanedResponse } = extractImagesAndButtons(response.data);
    response.data = marked(cleanedResponse);

    // Add the bot's response to messages
    messages.value.push({
      sender: "bot",
      //content: response.data?.replace(/\n/g, '<br>'),
      content: response.data,
      contentRaw: cleanedResponse,
      images: images,
      buttons: buttons,
      uploadButtons: uploadButtons,
      datetime: new Date(),
    });
  } catch (error) {
    console.error("Error sending message:", error.message);

    let default_message = "❌ An **error** occurred. Please make sure you have entered a valid token and **try again later.**"

    if (error.message === "Empty token") {
      default_message = "Please pass a valid token in the URL to start chatting. Format: https://chat.brokvisor.com/?token=YOUR_API_TOKEN";
    }

    // Push an error message to messages
    messages.value.push({
      sender: "bot",
      content: marked(default_message),
      contentRaw: default_message,
      datetime: new Date(),
    });
  } finally {
    // Reset the file input status
    // clearFileInput();
    isLoading.value = false;
    scrollToBottom();
  }
};

const sendButtonMessage = (message) => {
  currentMessage.value = message;
  sendMessage();
};

const uploadFileAndSendButtonMessage = (message) => {
  const fileInput = document.getElementById("fileInput");

  // Add a one-time event listener for the 'change' event
  fileInput.addEventListener(
    "change",
    function handleFileSelection() {
      if (fileInput.files.length > 0) {
        // File has been selected
        currentMessage.value = message;
        sendMessage();

        // Remove the event listener to prevent it from triggering multiple times
        fileInput.removeEventListener("change", handleFileSelection);
      }
    }
  );

  // Simulate a click on the file input to open the file picker
  fileInput.click();
};

const sendFeedback = async (positive) => {

  isLoading.value = true;

  const contentStr = positive ? "👍" : "👎";
  messages.value.push({
    sender: "me",
    content: contentStr,
    contentRaw: contentStr,
    datetime: new Date(),
  });
  scrollToBottom();

  try {
    const response = await axios.get(
      `https://fastapi.addin.brokvisor.com/users/chat`,
      {
        params: {
          question: "",
          feedback_is_positive: positive,
        }, // Sending feedback
        headers: {
          Authorization: `Bearer ${token.value}`, // Include Bearer token
        },
      }
    );

    // Add the bot's response to messages
    messages.value.push({
      sender: "bot",
      content: response.data, // Assuming the response has an 'answer' field
      contentRaw: response.data,
      datetime: new Date(),
    });
  } catch (error) {
    // console.error("Error sending feedback:", error.message);

    // Push an error message to messages
    const contentStr = "❌ An error occurred. Please try again later.";
    messages.value.push({
      sender: "bot",
      content: contentStr,
      contentRaw: contentStr,
      datetime: new Date(),
    });
  } finally {
    isLoading.value = false;
    scrollToBottom();
  }
};

const handleClickOutside = (event) => {
  if (!event.target.closest(".chat-interface") && isChatOpen.value) {
    isChatOpen.value = false;
  }
};

const handleFileUpload = () => {
  const fileInput = document.getElementById("fileInput");
  const fileInputWrapper = document.querySelector(".file-input-wrapper");
  const fileInputLabel = document.querySelector("label[for='fileInput']");
  fileInputLabel.textContent = fileInput.files[0].name;

  console.log("DEBUG: Selected file:", fileInput.files[0]);

  if (fileInput.files.length > 0) {
    fileIsSelected.value = true;
    fileInputWrapper.setAttribute("data-status", "selected");
  } else {
    fileIsSelected.value = false;
    fileInputWrapper.removeAttribute("data-status");
  }
};

const clearFileInput = () => {
  const fileInput = document.getElementById("fileInput");
  const fileInputWrapper = document.querySelector(".file-input-wrapper");
  const fileInputLabel = document.querySelector("label[for='fileInput']");
  fileInputLabel.textContent = "Upload portfolio";
  fileInput.value = "";
  fileIsSelected.value = false;
  fileInputWrapper.removeAttribute("data-status");
};

const validateToken = async () => {

  if (!token.value) {
    return;
  }

  try {
    const response = await axios.get(`https://fastapi.addin.brokvisor.com/verify_token`, {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    });

    if (response.status !== 200) {
      token.value = "";
      throw new Error("Invalid token");
    }

    const permissions = response?.data?.permissions;

    // const permissions = ["chat-numerical-search", "chat-full-search"];

    if (permissions.includes("chat-full-search")) {
      selectedOption.value = "both";
    }
    else if (permissions.includes("chat-numerical-search")) {
      selectedOption.value = "num";
    }
    // console.log("User permissions:", permissions);
    // console.log("Selected option:", selectedOption.value);
  } catch (error) {
    console.error("Error validating token:", error.message);
  }
};

const handleIframeMsg = (event) => {

  // Ignore messages that do not have a question property
  if (!event.data.question) {
    return;
  }

  console.log("DEBUG: Received message from iframe:", event.data);

  const iframeMsg = event.data;
  const question = iframeMsg.question;
  let portfolio = iframeMsg.portfolio;

  // Set titles to 1 if not present
  // (for unfinished portfolios from the website)
  portfolio = portfolio.map((item) => {
    if (!item.titles) {
      item.titles = 1;
    }
    return item;
  });

  const formDataCSV = createCSVFile(portfolio);

  currentMessage.value = question;
  sendMessage(formDataCSV);
};

function convertToCSV(data) {
    // Extract the headers from the keys of the first object
    const headers = Object.keys(data[0]);

    // Prepare CSV rows
    const rows = data.map(row =>
        headers.map(key => {
            let value = row[key];

            // Handle decimal separator (replace dot with comma)
            if (typeof value === "number") {
                return value.toString().replace('.', ',');
            }

            // Convert other types to string and handle null/undefined
            if (value !== null && value !== undefined) {
                // Remove any semicolons from the value
                value = value.toString().replace(/;/g, '');
            } else {
                value = '';
            }

            return value;
        }).join(';') // Use `;` as column separator
    );

    // Add the headers to the beginning of the CSV
    const csvStr = [headers.join(';')].concat(rows).join('\r\n'); // Join rows with CRLF
    console.log("DEBUG: CSV string:", csvStr);
    return csvStr;
}

function createCSVFile(data) {
    const csvString = convertToCSV(data);

    // Convert the string to a Blob with latin-1 encoding
    const csvBlob = new Blob([new TextEncoder('latin1').encode(csvString)], {
        type: 'text/csv;charset=latin1;',
    });

    // Create a FormData object and append the file
    const formData = new FormData();
    formData.append('fileList', new File([csvBlob], 'data.csv', { type: 'text/csv' }));

    return formData;
}

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
  window.addEventListener("message", handleIframeMsg);

  // Read token from URL parameters
  const urlParams = new URLSearchParams(window.location.search);
  token.value = urlParams.get("token") || "";
  let color = urlParams.get("color") || "004851";
  const showLogoParam = urlParams.get("logo");
  if (showLogoParam === "false") {
    showLogo.value = false;
  } else {
    showLogo.value = true;
  }
  document.querySelector(".chat-header").style.backgroundColor = `#${color}`;
  document.querySelector(".chat-footer").style.backgroundColor = `#${color}`;

  // If token has a value, try to make a request to validate it and get user permissions
  if (token.value) {
    validateToken();
  }
});

onUnmounted(() => {
  document.removeEventListener("click", handleClickOutside);
  window.removeEventListener("message", handleIframeMsg);
});

watch(() => isChatOpen.value, scrollToBottom);
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

#fileInput {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}


.chat-footer .search-option {
  margin-right: 12px;
  padding-bottom: 10px;
  padding-top: 10px;
  border-radius: 4px;
}

html,
body {
  min-height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.chat-content {
  min-height: 80%;
  max-height: 80%;
}

.chat-header {
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  padding-left: 12px;
  height: 60px;
  background-color: #004851;
  color: white;
  font-family: 'Roboto', sans-serif;
}

.chat-body {
  border: 1px solid #e0e0e0;
  height: calc(100vh - 136px);
  padding: 16px;
  overflow-y: auto;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

.chat-message {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.chat-message p {
  border-radius: 4px;
  padding: 8px 12px;
  font-family: 'Roboto', sans-serif;
  margin-top: 8px;
}

.chat-message.me p {
  background-color: #dfe9f2;
}

.chat-message.bot p {
  background-color: rgb(250, 244, 237);
}

.chat-message small {
  margin-left: 4px;
}

.chat-footer {
  border-radius: 0px 0px 8px 8px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  height: 60px;
  background-color: #004851;
  color: white;
}

.chat-footer input {
  flex: 1;
  padding: 12px;
  border: none;
  border-radius: 4px;
  margin-right: 12px;
  font-family: 'Roboto', sans-serif;
}

.chat-footer .like {
  display: none;
  cursor: pointer;
}

.chat-footer .dislike {
  display: none;
  cursor: pointer;
  margin-right: 14px;
}

.chat-images {
  display: flex;
  flex-direction: column; /* Stack images vertically */
  gap: 8px; /* Add spacing between images */
  align-items: flex-start; /* Align images to the left */
}

.chat-image {
  max-width: 100%; /* Restrict width to the container */
  height: auto;
  width: calc(min(900px, 100vw));
  border-radius: 8px; /* Remove border radius if not needed */
  border: 2px solid #bbb; /* Add border to images */
  object-fit: contain;
  margin: 0; /* Remove any default margins */
}

.copy-button {
  margin-top: 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;
  /* Vertically align text and icon */
  display: flex;
  align-items: center;
}

.chat-image-container {
  position: relative; /* Enable positioning of child elements */
  display: inline-block; /* Ensure the container sizes to fit the image */
}

.download-button {
  position: absolute; /* Position relative to the container */
  bottom: 12px; /* Adjust position from the bottom of the container */
  right: 8px; /* Adjust position from the right of the container */
  padding: 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background for contrast */
  color: white; /* Make text color white for visibility */
  display: flex;
  align-items: center;
  gap: 4px; /* Space between icon and text */
  z-index: 10; /* Ensure the button is above the image */
}

.copy-image-button {
  position: absolute; /* Position relative to the container */
  bottom: 12px; /* Adjust position from the bottom of the container */
  left: 8px; /* Adjust position from the right of the container */
  padding: 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background for contrast */
  color: white; /* Make text color white for visibility */
  display: flex;
  align-items: center;
  gap: 4px; /* Space between icon and text */
  z-index: 10; /* Ensure the button is above the image */
}

.material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
  font-size: 18px;
}

.copy-button:hover {
  background-color: #ccc;
}

.chat-buttons {
  display: flex;
  gap: 8px;
  margin-top: 4px;
  flex-wrap: wrap;
}

.chat-button {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background-color: #293a72;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.chat-button span {
  margin-right: 4px;
}

.chat-button:hover {
  background-color: #566fc2;
}

.file-input-wrapper {
  /* display: flex; */
  display: none;
  justify-content: center;
  align-items: center;
  flex: 0.12;
  padding: 0px;
  border: none;
  border-radius: 4px;
  margin-right: 8px;
  font-family: "Roboto", sans-serif;
  background-color: #ccc;
  min-height: 68%;
}

.file-input-wrapper[data-status="selected"] {
  background-color: #aaccaa;
}

.file-input-wrapper label {
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: small;
  font-weight: 600;
  color: #101010;
  cursor: pointer;
  margin-left: 4px;
  margin-right: 4px;
}

.file-input-wrapper span {
  cursor: pointer;
  color: red;
  margin-left: 4px;
}
</style>
